import React from 'react'
import imgDubaihorizontal from "../../assets/Dubaihorizontal-new.png"

import './Horizontal.css'
import { Col, Row } from 'react-bootstrap'

const Horizontal = () => {
    return (
        <div className="horizontalImageBody">
                <img src={imgDubaihorizontal} alt="DubaiImage" className="responsive-image" />
        </div>
    )
}

export default Horizontal